const Configs = {};

Configs.appConfigs = {
  port: '3001',
};

const apiUrl = 'https://api.segokoriental.cocore.co.kr/rest';
Configs.apiConfigs = {
  baseUrl: apiUrl,
  baseQuery: '?branchId=',
  authURL: `${apiUrl}/auth/token`,
  userURL: `${apiUrl}/web/member`,
  userChangeURL: `${apiUrl}/web/users/change`,
  userPwdChangeURL: `${apiUrl}/web/users/change/pwd`,
  userSchedulesURL: `${apiUrl}/web/schedules/member`,
  userCountBookedSchedulesURL: `${apiUrl}/web/schedules/member/book/cnt`,
  userGroupSchedulesURL: `${apiUrl}/web/schedules/group`,
  userPaymentURL: `${apiUrl}/web/payment`,
  teacherURL: `${apiUrl}/web/webteacher`,
  teacherChangeURL: `${apiUrl}/web/webteacher/change`,
  teacherPwdChangeURL: `${apiUrl}/web/webteacher/change/pwd`,
  schedulesURL: `${apiUrl}/schedules`,
  scheduleURL: `${apiUrl}/schedule`,
  roomsURL: `${apiUrl}/schedules/rooms`,
  membersURL: `${apiUrl}/members`,
  memberURL: `${apiUrl}/member`,
  membersCountURL: `${apiUrl}/members/count`,
  consultingURL: `${apiUrl}/consulting`,
  workoutURL: `${apiUrl}/workout`,
};

Configs.serviceConfigs = {
  serviceName: 'Oriental Pilates',
  serviceShortName: 'Oriental',
  title: 'Oriental Pilates',
  icon: 'icon.png',
  logo: 'logo.png',
  classType: ['PT', '4:1', '6:1'],
  ptClassType: ['PT'],
  groupClassType: ['4:1', '6:1'],
  branch: ['전지점', '강남세곡점'],
  programType: ['개인', '듀엣', '그룹', '비기너'],
  days: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
  memberLevel: [' 비방문상담', ' 방문상담', ' 체험상담', ' 회원', ' 휴지회원', ' 종료회원', '기간제회원', '쿠폰제회원'],
  sessionMemberLevel: [' 회원', ' 휴지회원', ' 종료회원'],
  nonmemberLevel: [' 비방문상담', ' 방문상담', ' 체험상담'],
  dFormat: 'YYYY-MM-DD',
  dtFormat: 'YYYY-MM-DD HH:mm',
  classDuriation: 50,
  groupBookingLimit: [2, 2], //최대 예약할 수 있는 기간(주 단위)
  groupBookingCancleLimit: [3, 12], //수업 취소 제한 시간(시 단위)
  groupBookingAvaibleLimit: [0, 0], //수업 예약이 가능한 제한 시간. 수업 시간 0분 전까지. 0분으로 설정 시
  defaultPTSeats: [1, 1],
  defaultDuetSeats: [4, 4],
  canBookingCancleClassType: [2, 3],
  canBookingCancleClassTypeName: ['4:1', '6:1'],
  usingWaiting: [true, true],
  //2021.05.12 - 동일 시간에 예약/대기 불가 하도록 제한. 지점 별로 제한
  restrictBookSameTime: [false, false],
  manualURL: '',
  // 2020.02.02
  bookNotification: `안녕하세요. @memberName님 \n` + `회원님은 세션 만료일, @expiredDate 수업까지 예약이 가능합니다.\n` + `예약된 수업 메뉴에서 내역 확인과 취소가 가능합니다.\n`,
  bookNotificationDanger:
    `수업 예약은 수업시간 기준으로 @groupBookingLimit주 전부터 수업 시작 전까지 예약하실 수 있습니다.\n` +
    `예) 2월 17일 월요일 오후 7시 수업을 예약하고 싶으신 경우, 2월 3일 월요일 오후 7시에 수업 예약이 열립니다.\n` +
    `취소는 @groupBookingCancleLimit시간 전까지 가능하며,\n` +
    `아래는 예약 가능 기간의 수업만 표시됩니다. \n` +
    `취소하지 않은 예약은 수업 종료 후 세션이 차감되니, 주의를 부탁 드립니다.`,
  bookRestrictNotification: `예약 및 대기한 시간과 동일한 시간의 수업은 예약 및 대기할 수 없습니다.`,
};

Configs.logConfigs = {
  path: '/home/app/workspace/logs/',
  tsFormat: 'HH:mm:ss',
};

module.exports = Configs;
