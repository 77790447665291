import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function NotificationModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>수업 안내</Modal.Title>
      </Modal.Header>

      {/* <Modal.Body>
        안녕하세요 문래 드림필라테스 입니다!
        <br />
        <br />
        11월 27일(월)부터 오전 그룹수업 담당 강사님이 변경됩니다.
        <br />
        - 월/수 오전 7시 : 최연주 원장님
        <br />
        - 월/수 오전 9시, 10시 : 김수란 강사님
        <br />
        <br />
        수업 예약 시 참고하시길 바랍니다.
      </Modal.Body> */}

      <Modal.Body>
        <div className="NotificationModal">
          <img src="https://storage.googleapis.com/storage.cocore.co.kr/images/Dream_noti_20240111.jpeg" />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NotificationModal;
